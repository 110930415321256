.react-datepicker-input input {
    font-family: "Lato", sans-serif;
    font-size: 0.9rem;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 36px;
    line-height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}
.react-datepicker-component {
    position:relative;
    font-size:1rem;
    font-weight:400;
    margin-bottom: 0.5rem;
}
.react-datepicker-component .react-datepicker {
    margin-left:5px;
    margin-top:5px;
    display:inherit;
}
.react-datepicker-input input:focus {
    outline:none;
}
.react-datepicker-input input::-webkit-input-placeholder {
    color:#a7abab;
}
.react-datepicker-input input:-moz-placeholder,
.react-datepicker-input input::-moz-placeholder {
    color:#a7abab;
}
.react-datepicker-input input:-ms-input-placeholder {
    color:#a7abab;
}
.react-datepicker-input.is-disabled {
    opacity:.6;
}
.react-datepicker-input .button-wrapper {
    position:absolute;
    right:0;
    top:0;
    height:100%;
    display:flex;
    align-items:center
}
.react-datepicker-input .input-button {
    margin:0;
    height:100%;
    width:42px;
    display:flex;
    display:-webkit-flex;
    align-items:center;
    -webkit-align-items:center;
    justify-content:center;
    -webkit-justify-content:center;
    background:#f5f5f5;
    border: 1px solid #ccc;
    border-radius:0 4px 4px 0;
    cursor:pointer;
}
.react-datepicker-input .input-button:hover {
    background:#c7c7c7;
    transition-timing-function: ease-in;
}
.react-datepicker-input .clear-button {
    margin-right:10px;
    cursor:pointer;
    font-size:1rem;
    color:#a7abab;
}
.react-datepicker-input .clear-button:hover {
    color:#c93132;
}
.react-datepicker.floating {
    position:absolute;
    z-index:10000;
}
.react-datepicker {
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    display:inline-block;
}
.react-datepicker .react-datepicker-container {
    border:1px solid #d0d0d0;
    border-radius:6px;
    width:250px;
}
.react-datepicker .react-datepicker-top {
    text-align:center;
    background:#6db2da;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    color:#fff;
}
.react-datepicker .react-datepicker-top .week-days {
    height:35px;
    display:flex;
    justify-content:center;
    align-items:center;
}
.react-datepicker .react-datepicker-top .week-days .week-day {
    flex:1 1 auto;
    cursor:default;
}
.react-datepicker .react-datepicker-top .display {
    height:35px;
    display:flex;
}
.react-datepicker .react-datepicker-top .display .react-datepicker-button {
    display:flex;
    justify-content:center;
    align-items:center;
    flex:1 0 auto;
    text-decoration:none;
    padding:4px;
    text-align:center;
}
.react-datepicker .react-datepicker-top .display .react-datepicker-button.button-left {
    border-top-left-radius:5px;
}
.react-datepicker .react-datepicker-top .display .react-datepicker-button.button-right {
    border-top-right-radius:5px;
}
.react-datepicker .react-datepicker-top .display .react-datepicker-button.button-left .slow,
.react-datepicker .react-datepicker-top .display .react-datepicker-button.button-right .fast {
    margin-left:10px;
}
.react-datepicker .react-datepicker-top .display .react-datepicker-button.button-label {
    width:120px;
}
.react-datepicker .react-datepicker-top .display .react-datepicker-button.button-label.fixed:hover {
    background:transparent;
    cursor:default;
}
.react-datepicker .react-datepicker-top .display .react-datepicker-button.button-label:hover {
    background:#009bde;
}
.react-datepicker .react-datepicker-row {
    display:flex;
    margin-top:0;
    width:100%;
}
.react-datepicker .react-datepicker-row:not(:last-child) {
    border-bottom:1px solid #d0d0d0;
}
.react-datepicker .react-datepicker-row:last-child .react-datepicker-picker:first-child {
    border-bottom-left-radius:5px;
}
.react-datepicker .react-datepicker-row:last-child .react-datepicker-picker:last-child {
    border-bottom-right-radius:5px;
}
.react-datepicker .react-datepicker-picker {
    color:#a7abab;
    background:#fff;
    cursor:pointer;
    text-decoration:none;
    flex:1 1 100%;
    display:flex;
    align-items:center;
    justify-content:center;
}
.react-datepicker .react-datepicker-picker:not(:last-child) {
    border-right:1px solid #d0d0d0;
}
.react-datepicker .react-datepicker-picker.day {
    height:35px;
    line-height: 33px;
}
.react-datepicker .react-datepicker-picker.month,
.react-datepicker .react-datepicker-picker.year {
    height:62px;
    line-height: 60px;
}
.react-datepicker .react-datepicker-picker:hover {
    color:#2d373c;
    background:alabaster;
}
.react-datepicker .react-datepicker-picker.selected {
    color:#fff;
    background:#6db2da;
}
.react-datepicker .react-datepicker-picker.current {
    color:#2d373c;
    background:#f3f3f3;
}
.react-datepicker .react-datepicker-picker.current:hover {
    color:#2d373c;
    background:#d0d0d0;
}
.react-datepicker .react-datepicker-picker.selected.current {
    color:#fff;
    background:#6db2da;
}
.react-datepicker .react-datepicker-picker.disabled {
    cursor:default;
    color:#d0d0d0;
    background:#fff;
}
.react-datepicker .react-datepicker-picker.disabled:hover {
    color:#d0d0d0;
    background:#fff;
}
.react-datepicker .react-datepicker-button {
    display:block;
    text-align:center;
    cursor:pointer;
    text-decoration:none;
}
.react-datepicker .react-datepicker-button:hover {
    background:#009bde;
}
